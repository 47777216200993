<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.orders") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.day"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.day.show">
            <el-date-picker
              v-model="filterForm.day"
              :placeholder="columns.day.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </th>
          <th v-if="columns.service.show">
            <!-- <select-service-name-inventory v-model="filterForm.service_id" :placeholder="columns.service.title" size="mini">
            </select-service-name-inventory> -->
            <el-input
              size="mini"
              v-model="filterForm.service_name"
              :placeholder="columns.service.title"
              clearable
            >
            </el-input>
          </th>
          
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            ></el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="patient in list"
          :key="patient.id"
          v-on:dblclick="show(patient.id)"
          style="cursor: pointer"
        >
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              patient.patient && patient.patient.surname
                ? patient.patient.surname
                : ""
            }}
            {{ patient.patient ? patient.patient.first_name : "" }}
          </td>
          <td v-if="columns.day.show">{{ patient.day }}</td>
          <td v-if="columns.service.show">
            <p
              v-for="(item, index) in patient.services"
              :key="index"
              class="p-0 m-0"
            >
              {{ item ? item.name : "" }}
            </p>
          </td>
         
          <td v-if="columns.created_at.show">
            {{ patient.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <div class="za_btn">
              <el-button
                @click="show(patient)"
                type="success"
                icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
              >
              <el-button
                @click="createModal(patient.id)"
                type="primary"
                icon="el-icon-edit"
                >{{ $t("message.receive_") }}</el-button
              >

              <el-button
                @click="showReturn(patient.reason_for_return)"
                type="danger"
                v-if="patient.reason_for_return"
                icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
              >
            </div>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-drawer
      :visible.sync="drawerShowCoculation"
      size="85%" :wrapperClosable="false"
      ref="drawerShowCoculation"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @opened="drawerOpened('drawerShowCoculationChild')"
      @closed="drawerClosed('drawerShowCoculationChild')"
      class="mytab_IdP"
    >
      <div>
        <crm-show
          :reloadModel="reopenUpdate"
          :selected="selectedModel"
          :patient="selected_patient"
          @open="reopenUpdate = true"
          ref="drawerShowCoculationChild"
          :drawer="drawerShowCoculation"
          @c-close="closeDrawer"
        ></crm-show>
      </div>
    </el-drawer>

    <el-dialog
      :append-to-body="true"
      :title="$t('message.back')"
      :visible.sync="dialogTableVisible"
      width="30%"
    >
      <h5>{{ reason_return }}</h5>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createConclusion";
import CrmUpdate from "./components/addOrder";
import CrmShow from "./components/crm-show";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import Inventory from '@/components/inventory-select/select-service_name-inventory';
import { i18n } from "@/utils/i18n";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    CrmCreate,
    CrmUpdate,
    CrmShow,
    'select-service-name-inventory': Inventory,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      drawerUpdateCoculation: false,
      drawerShowCoculation: false,
      filterForm: {},
      reopenUpdate: false,
      reopenShow: false,
      selectedModel: null,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      selected_patient: [],
      reason_return: [],
      dialogTableVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "laboratoryCabinet/informal_patient_list",
      columns: "laboratoryCabinet/columns",
      pagination: "laboratoryCabinet/pagination",
      filter: "laboratoryCabinet/filter",
      sort: "laboratoryCabinet/sort",
      conditions: "conditions/list",
    }),
  },
  async created() {},
  async mounted() {
    await this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions({ doctor: true });
    
  },
  methods: {
    ...mapActions({
      updateList: "laboratoryCabinet/informalPatientLaboratoryList",
      updateLaboratory: "laboratoryCabinet/updateLaboratory",
      updateSort: "laboratoryCabinet/updateSort",
      updateFilter: "laboratoryCabinet/updateFilter",
      updateColumn: "laboratoryCabinet/updateColumn",
      updatePagination: "laboratoryCabinet/updatePagination",
      empty: "laboratoryCabinet/empty",
      refreshData: "laboratoryCabinet/refreshData",
      loadConditions: "conditions/iventory",
      currentUser: "auth/getAuth",
    }),
    showReturn(data) {
      this.dialogTableVisible = true;
      this.reason_return = data;
    },
    async fetchData() {
      const user = await this.currentUser()

      const query = {
        doctor: user.name,
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    show(res) {
      this.drawerShowCoculation = true;
      this.selectedModel = res;
    },
    async createModal(id) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.updateLaboratory(id)
            .then((res) => {
              this.$loadingCursor("default");
              this.refresh();
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message:  i18n.t("message.operation_canceled"),
          });
        });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      // this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>



